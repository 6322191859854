<script lang="ts" setup>
import type { AvatarImageEmits } from 'radix-vue'
import { chooseFile } from 'core'

type Status = AvatarImageEmits['loadingStatusChange'][0]

const props = defineProps<{
  src?: string | null
  size?: `${number}` | number

  /** Name of profile. Initials is parsed from given value */
  name?: string

  /** Callback when image is picked */
  onPick?: (file: Blob) => Promise<void> | void
}>()

const size = +(props.size || 24)
const state = ref<Status>('idle')

function getInitials() {
  return String(props.name).slice(0, 1)
}

async function updateImage() {
  const { onPick } = props

  // Ignore if onPickImage is not needed
  if (!onPick)
    return

  const file = await chooseFile({
    accept: 'image/*'
  })

  if (file)
    await onPick(file)
}
</script>

<template>
  <AvatarRoot
    class="wrapper size-$size flex-shrink-0 rounded-full relative flex-center select-none overflow-hidden"
    :as="onPick ? 'button' : 'div'"
    :inert="state === 'loading'"
    @click.stop="updateImage"
  >
    <AvatarImage
      v-if="src"
      :src
      class="size-full rounded-inherit object-cover"
      decoding="async"
      draggable="false"
      alt=""
      @loading-status-change="state = $event"
    />

    <AvatarFallback>
      <Spinner v-if="state === 'loading'" class="$size-12px $width-2px" />
      <div v-else-if="name" class="font-size-$size-initials font-semibold uppercase tracking-wider" v-text="getInitials()" />
      <i-solar-user-bold v-else :style="{ width: size / 1.5, height: size / 1.5 }" />
    </AvatarFallback>
  </AvatarRoot>
</template>

<style scoped lang="sass">
.wrapper
  --size: v-bind(`${size}px`)
  --size-initials: calc(var(--size) * 0.4)
</style>
